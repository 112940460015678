/* eslint react-hooks/rules-of-hooks: 0 */

import React, { useState, useRef, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getGameById } from '../gameData';
import {
  getStoredGame,
  clearStoredGame,
  saveSuccess,
  updateStoredPuzzleResults,
  updateStoredTime,
} from '../dataStore';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import "./GameHub.css";
import "@splidejs/splide/dist/css/splide-core.min.css";

const themes = [ 
  'blue-silver',
  'red-green',
  'maroon-gold',
  'green-red',
  'silver-blue',
  'gold-maroon',
];

const negativeEmoji = [ 
  // '👇',
  '👎',
  // '😿',
  // '😫',
  // '😖',
  // '😢',
  // '🙅',
  // '🤦',
  // '❌',
  // '💔',
];

const positiveEmoji = [ 
  // '❤',
  // '😻',
  // '😎',
  // '🥳',
  // '😏',
  // '🤩',
  // '🤙',
  '👍',
  // '👏',
  // '🍾',
  // '🍻',
  // '🥂',
  // '🌈',
  // '🏆',
  // '🎯',
  // '💎',
  // '✔',
];

let loadCounter = 0;

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

function GameHub() {
  let params = useParams();
  let gameInterval;
  const gameData = getGameById(params.gameId);
  if (!gameData) {
    return "Invalid game id";
  }

  // Save Success for simple scan
  if (params.puzzleId && params.puzzleData) {
    if (gameData.puzzles.find(puzzle => puzzle.id === params.puzzleId && params.puzzleData === puzzle.data)) {
      saveSuccess(params.gameId, params.puzzleId);
    }
  }

  const questionCarouselRef = useRef();
  const storedGame = getStoredGame(params.gameId);
  const [completedPuzzles, setCompletedPuzzles] = useState(storedGame.puzzles);
  const [answerOrder, setAnswerOrder] = useState(shuffle([0, 1, 2, 3]));
  const [answerStatus, setAnswerStatus] = useState(null);
  const [gameTimer, setGameTimer] = useState(new Date(storedGame.timeElapsed));
  const [resultEmoji, setResultEmoji] = useState(positiveEmoji[0]);

  function handleAnswerRadioChange (e, answer) {
    const puzzleIndex = Number(e.currentTarget.value.split('-')[0]);
    const updatedPuzzles = completedPuzzles.slice();
    updatedPuzzles[puzzleIndex] = Number(gameData.puzzles[puzzleIndex][1] === answer);
    setResultEmoji(updatedPuzzles[puzzleIndex] === 0
      ? negativeEmoji[Math.floor(Math.random()*negativeEmoji.length)]
      : positiveEmoji[Math.floor(Math.random()*positiveEmoji.length)]
    );

    setCompletedPuzzles(updatedPuzzles);
    updateStoredPuzzleResults(params.gameId, updatedPuzzles);
  }

  useEffect( () => {
    if (gameInterval === undefined) {
      gameInterval = window.setInterval(() => {
        const updatedTime = Date.now();
        setGameTimer(updatedTime);
        updateStoredTime(params.gameId, updatedTime);
      }, 1000);
    }
  }, []);

  useEffect( () => {
    if (questionCarouselRef.current) {
      if (loadCounter < 1) {
        loadCounter++;
      } else {
        // show result css
        setAnswerStatus(completedPuzzles[completedPuzzles.length-1]);
        setTimeout(() => {
          setAnswerOrder(shuffle([0, 1, 2, 3]));
          questionCarouselRef.current.go(completedPuzzles.length);
          setAnswerStatus(null);
      }, 1000);
      }
    }
  }, [completedPuzzles]);

  const secondsRemaining = Math.floor((gameTimer - storedGame.startTime)/1000);
// let choice = shuffle(gameData.puzzles);
// console.log(JSON.stringify(choice));
  return (
    <>
      <ul className="allPuzzleMenu" aria-hidden={!gameData.debug}>
      {gameData.puzzles && gameData.puzzles.map((puzzleData, k) => (
        <li key={puzzleData[1]}>
          <Link to={`/${params.gameId}/${puzzleData}`}>{puzzleData[1]}</Link>
        </li>
      ))}
        <li><button onClick={() => clearStoredGame(params.gameId)}>CLEAR ALL</button></li>
      </ul>
      {gameData.timeLimit > gameTimer - storedGame.startTime && completedPuzzles.length < gameData.puzzles.length 
        ? (
          <div className={`clueCarouselWrapper ${answerStatus === 1 ? 'correct' : ''} ${answerStatus === 0 ? 'incorrect' : ''}`}>
            <Splide aria-label="Clues"options={ { start: completedPuzzles.length, keyboard: false, drag: false, pagination: false, arrows: false } } ref={ questionCarouselRef }>
            {gameData.puzzles.map((puzzle, i) => {
              const answerArr = [puzzle[1], puzzle[2], puzzle[3], puzzle[4]];
              return (
                <SplideSlide key={i} data-theme={themes[i%themes.length]}>
                  <div className="clueSlide">
                    {puzzle[5] && puzzle[5] !== '' && <img src={`/images/questions/${puzzle[5]}.jpg`} alt="" />}
                    <h2>{puzzle[0]}</h2>
                    <div className="radioGroup">
                      {answerArr.map((answer, j) => {
                        return (
                          <div key={`${answer}-${j}`} className="radioWrapper">
                            {answer !== ""
                              ? (<>
                                <input
                                  type="radio"
                                  id={`q-${i}-${j}`}
                                  name={`q-${i}`}
                                  value={`${i}-${j}`}
                                  disabled={answerStatus !== null}
                                  onChange={e => handleAnswerRadioChange(e, answerArr[answerOrder[j]])}
                                  className="element-invisible"
                                />
                                <label htmlFor={`q-${i}-${j}`} className="radioButton">
                                  {answerArr[answerOrder[j]]}
                                </label>
                              </>)
                              : ""
                            }
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </SplideSlide>
              )
            })}
            </Splide>
            <div className={`resultIndicator`}>{resultEmoji}</div>
            <div className="statusBar">
              <div>
              {`Time Elapsed: ${Math.floor(secondsRemaining/60)}:${String(secondsRemaining%60).length === 1 ? '0' + secondsRemaining%60 : secondsRemaining%60} of 20:00`}
              </div>
              <div>
                <span>{`Correct: ${completedPuzzles.filter(ans => ans === 1).length}`}</span>
                <span>{`Incorrect: ${completedPuzzles.filter(ans => ans === 0).length}`}</span>
                <span>{`Remaining: ${gameData.puzzles.length - completedPuzzles.length}`}</span>
              </div>
            </div>
          </div>
        )
        : (
          <div className="gameOver">
            {/* <h1>Thanks for playing the 2022 Nova and Rev Holiday Trivia</h1> */}
            <p className="finalScore">{`Your Score is ${completedPuzzles.filter(ans => ans === 1).length}`}</p>
          </div>
        )
      }
    </>
  );
}

export default GameHub;
