import React from "react";
import {
  Routes,
  Route,
} from "react-router-dom";

import GameSelect from './pages/GameSelect';
import GameHub from './pages/GameHub';

export default function AppRouter() {
  return (
    <Routes>
      <Route exact path="/" element={<GameSelect />} />
      <Route path="/:gameId" element={<GameHub />} />
    </Routes>
  );
}