function getStoredGame (gameId) {
  const savedDataStr = typeof window !== 'undefined' ? localStorage.getItem(gameId) : null;
  if (savedDataStr) {
    return JSON.parse(savedDataStr);
  }

  // Create New Game Data by Default
  const newStoredGame = {
    id: gameId,
    startTime: Date.now(),
    timeElapsed: Date.now(),
    puzzles: []
  };
  localStorage.setItem(gameId, JSON.stringify(newStoredGame));
  return newStoredGame;
}

function getStoredPuzzle (gameId, puzzleId) {
  const storedGame = getStoredGame(gameId);
  let puzzleData = storedGame.puzzles.find(puzzle => puzzle.id === puzzleId);
  
  if (!puzzleData) {
    // Create New Puzzle Data by Default
    puzzleData = {
      id: puzzleId,
      data: [],
      pass: false,
    }

    storedGame.puzzles.push(puzzleData);
    localStorage.setItem(gameId, JSON.stringify(storedGame));
  }

  return puzzleData;
}

function updateStoredPuzzle (gameId, puzzleData) {
  const storedGame = getStoredGame(gameId);
  const puzzleIndex = storedGame.puzzles.findIndex(puzzle => puzzle.id === puzzleData.id);
  
  if (puzzleIndex < 0) {
    storedGame.puzzles.push(puzzleData);
  } else {
    storedGame.puzzles[puzzleIndex] = puzzleData;
  }
  // console.log(storedGame);
  localStorage.setItem(gameId, JSON.stringify(storedGame));
}

function updateStoredPuzzleResults (gameId, puzzleData) {
  const storedGame = getStoredGame(gameId);

  storedGame.puzzles = puzzleData;
  // console.log(storedGame);
  localStorage.setItem(gameId, JSON.stringify(storedGame));
}

function updateStoredTime (gameId, timeElapsed) {
  const storedGame = getStoredGame(gameId);

  localStorage.setItem(gameId, JSON.stringify({ ...storedGame, timeElapsed }));
}

function clearStoredPuzzle (gameId, puzzleId) {
  const storedGame = getStoredGame(gameId);
  const puzzleIndex = storedGame.puzzles.findIndex(puzzle => puzzle.id === puzzleId);
  if (puzzleIndex >= 0) {
    storedGame.puzzles.splice(puzzleIndex, 1);
    localStorage.setItem(gameId, JSON.stringify(storedGame));
  }
}

function clearStoredGame (gameId) {
  localStorage.removeItem(gameId);
}

function saveSuccess (gameId, puzzleId) {
  const storedPuzzleData = getStoredPuzzle(gameId, puzzleId);
  updateStoredPuzzle(gameId, { ...storedPuzzleData, pass: true });
}

function saveResult (gameId, puzzleId, result) {
  const storedGame = getStoredGame(gameId);
  storedGame.puzzles[puzzleId] = result;
  updateStoredPuzzleResults(gameId, storedGame.puzzles);
}

function getPassedPuzzles (gameId) {
  const storedGame = getStoredGame(gameId);
  return storedGame.puzzles.filter(puzzle => puzzle.pass === true);
}

export {
  getStoredGame,
  getStoredPuzzle,
  updateStoredPuzzle,
  saveSuccess,
  clearStoredPuzzle,
  getPassedPuzzles,
  clearStoredGame,
  saveResult,
  updateStoredPuzzleResults,
  updateStoredTime,
};